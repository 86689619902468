import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { Button, Container, Spacer, Text } from '@vinted/web-ui'

import styles from './error.module.css'

interface Props {
  content: { title: string }
}

const Page = (props: Props) => {
  const { t } = useTranslation('common')

  return (
    <Container styling={Container.Styling.Wide}>
      <Head>
        <title>{props.content.title}</title>
      </Head>
      <div className={styles.wrapper}>
        <Spacer size={Spacer.Size.X5Large} />
        <div className={styles.imageWrapper}>
          <Image src="/assets/svg/error.svg" alt="Error" priority fill />
        </div>
        <Spacer size={Spacer.Size.X3Large} />
        <Text text={t('genericErrorPage.title')} type={Text.Type.HeadingXXL} />
        <Spacer size={Spacer.Size.X3Large} />
        <Link href="/">
          <Button text={t('genericErrorPage.actions.back')} styling={Button.Styling.Filled} />
        </Link>
      </div>
    </Container>
  )
}

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  return {
    props: {
      content: { title: 'Routes by Vinted Go | Failure' },
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  }
}

export default Page
